import React, { useEffect, useState } from "react";
import { Period, PeriodManager } from "../../services/periodManger";

export function Stats() {
    const [periods, setPeriods] = useState<Period[]>([]); //they should sorted by date in ascending order
    const [periodManager] = useState(() =>
        PeriodManager.create("period_manager")
    );
    // hook to attach event handlers for periods
    useEffect(() => {
        const handlePeriodsChange = (periods: Period[]) => {
            const periodDates = periods.map((period) => ({ ...period }));
            setPeriods(periodDates);
        };
        periodManager.on("change", handlePeriodsChange);
        return () => {
            periodManager.off("change", handlePeriodsChange);
        };
    }, [periodManager]);

    useEffect(() => {
        if (periods.length === 0) return;
        const timeDiffs = getTimeDiff(periods);
        periods.forEach((period, i) => {
            console.log(period.date.toDateString());
            console.log(timeDiffs[i]);
        });
        console.log(
            `Next period prediction: ${periodManager
                .predictNextPeriod()
                .toDateString()}`
        );
    }, [periodManager, periods]);

    // a function to calculate day difference between each dates in periods
    const getTimeDiff = (periods: Period[]) => {
        const timeDiffs: number[] = [];
        // no point of calculating time difference if there is only one period
        if (periods.length <= 1) return [];
        for (let i = 1; i < periods.length; i++) {
            const period = periods[i];
            const prevPeriod = periods[i - 1];
            const timeDifference =
                period.date.getTime() - prevPeriod.date.getTime();
            const inDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            timeDiffs.push(inDays);
        }
        return timeDiffs;
    };

    const periodListJsx = periods.map((period) => {
        return (
            <div key={period.date.toString()}>
                <li>{period.date.toDateString()}</li>
            </div>
        );
    });

    return (
        <div>
            <ul>{periodListJsx}</ul>
        </div>
    );
}
